import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
    return(
        <footer className="h-64 bg-deep-blue pt-10">
            <div className="w-5/6 mx-auto flex flex-col justify-center items-center">
                <SocialMediaIcons/>
                    <p className="font-montserrat font-semibold text-2xl"> Shawn Maisonneuve</p>
            </div>
        </footer>
    )
}

export default Footer;