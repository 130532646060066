import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import nodeImage from "../assets/nodejs-logo.png";
import tailwind from "../assets/tailwind.png";
import github from "../assets/github.png";
import illustrator from "../assets/Ai.ico";
import csharp from "../assets/csharp.png";
import { useRef } from "react";

const MySkills = () => {

  const constraintsRef = useRef(null);

  const container = {
    
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
  
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const techs = [
    {
      id: 1,
      src: html,
      title: "HTML",
      style: " border-orange-500 hover:shadow-orange-500",
    },
    {
      id: 2,
      src: css,
      title: "CSS",
      style: "border-blue hover:shadow-blue",
    },
    {
      id: 3,
      src: javascript,
      title: "JavaScript",
      style: "border-yellow hover:shadow-yellow",
    },
    {
      id: 4,
      src: reactImage,
      title: "React",
      style: "border-cyan-300 hover:shadow-cyan-300",
    },
    {
      id: 5,
      src: csharp,
      title: "C#/.Net",
      style: "border-purple-500 hover:shadow-purple-500",
    },
    {
      id: 6,
      src: tailwind,
      title: "Tailwind",
      style: "border-cyan-600 hover:shadow-cyan-600",
    },{
      id: 7,
      src: nodeImage,
      title: "Node Js",
      style: "border-green-400 hover:shadow-cyan-400",
    },{
      id: 8,
      src: illustrator,
      title: "Illustrator",
      style: "border-orange-400 hover:shadow-orange-400",
    },{
      id: 9,
      src: github,
      title: "Github",
      style: "border-white hover:shadow-white",
    },
  ];

  // const techs = [
  //   {
  //     id: 1,
  //     src: html,
  //     title: "Attaque",
  //     style: " border-orange-500 hover:shadow-orange-500",
  //   },
  //   {
  //     id: 2,
  //     src: html,
  //     title: "Centre",
  //     style: "border-purple-500 hover:shadow-purple-500",
  //   },
  //   {
  //     id: 3,
  //     src: html,
  //     title: "Pass",
  //     style: "border-cyan-300 hover:shadow-cyan-300",
  //   },
  //   {
  //     id: 4,
  //     src: html,
  //     title: "Tech",
  //     style: "border-yellow hover:shadow-yellow",
  //   },
  //   {
  //     id: 5,
  //     src: html,
  //     title: "Centre",
  //     style: "border-purple-500 hover:shadow-purple-500",
  //   },
  //   {
  //     id: 6,
  //     src: html,
  //     title: "Attaque",
  //     style: " border-orange-500 hover:shadow-orange-500",
  //   }
  // ];

  return (
    <section id="skills" className="pt-10 pb-24">
      {/*Header and Image Section*/}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-montserrat font-semibold text-4xl mb-5">
          <span className="letters max-sm:block select-none">
              <span>M</span>
              <span>e</span>
              <span>s</span>
              {" "}
            </span>
             <span className="text-yellow"> 
            <span className="letters select-none">
              <span>C</span>
              <span>o</span>
              <span>m</span>
              <span>p</span>
              <span>é</span>
              <span>t</span>
              <span>e</span>
              <span>n</span>
              <span>c</span>
              <span>e</span>
              <span>s</span>
            </span>
            </span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">
            {" "}
            Voici les technologies avec lesquelles je travaille.
          </p>
        </motion.div>
      </div>
      <div className="mt-16 md:mt-0">
        
          <div className="flex space-x-2 justify-center">
            <div className="relative max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white hover:shadow-neon-white transition duration-500 rounded-3xl" 
              ref={constraintsRef}  >
              <motion.div             
              className="w-full grid grid-cols-2 sm:grid-cols-3 max-sm:justify-items-center md:grid-cols-5 gap-8 text-center py-8 px-12 sm:px-0 "
              variants={container}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
              >
                {techs.map(({ id, src, title, style }) => (
                   <motion.div
                   key={id}
                   drag
                  dragConstraints={constraintsRef}
                  dragTransition={{  bounceDamping: 20 }}
                   className={` clickable bg-deep-blue box border-8 rounded-3xl min-w-[100px] text-sm shadow-3xl ${style}`}
                   variants={item}
                   whileHover={{ scale: [null, 1.5, 1.4], zIndex: 20}}
                   transition={{ duration: 0.5 }}
                 > 
                  <div
                    key={id}
                    className={`shadow-md  py-2 rounded-lg `}
                  >
                    <img src={src} alt="" className="w-12 sm:w-20 mx-auto " />
                    <p className="mt-4">{title}</p>
                  </div></motion.div>
                ))}
              </motion.div>
            </div>
            {/* <div className="relative max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white shadow-neon-white transition duration-500 rounded-3xl" 
              ref={constraintsRef}  >

<div className=" absolute w-full h-[40%] top-0 left-0 border-b-4 border-red"></div>
              <motion.div             
              className="w-full grid grid-cols-2 sm:grid-cols-3 max-sm:justify-items-center md:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0 "
              variants={container}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
              >
                {techs.map(({ id, src, title, style }) => (
                   <motion.div
                   key={id}
                   drag
                  dragConstraints={constraintsRef}
                  dragTransition={{  bounceDamping: 20 }}
                   className={` clickable bg-slate-300 text-white font-bold  box border-8 rounded-3xl max-w-[100px] max-h-[110px] text-sm shadow-3xl ${style}`}
                   variants={item}
                   whileHover={{ scale: [null, 1.5, 1.4], zIndex: 20}}
                   transition={{ duration: 0.5 }}
                 > 
                  <div
                    key={id}
                    className={`shadow-md  py-2 rounded-lg `}
                  >
                    <img src={src} alt="" className="w-12 sm:w-20 mx-auto " />
                    <p className="mt-4">{title}</p>
                  </div></motion.div>
                  
                ))}
              </motion.div>
              
            </div> */}
          </div>
        
      </div>
    </section>
  );
};
export default MySkills;
