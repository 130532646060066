import { AiFillGithub } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';

const SocialMediaIcons = () => {
    return (
        <div className="flex justify-center md:justify-start my-10 gap-7">
            <a 
            className="hover:opacity-50 transition duration-500" 
            href="https://www.linkedin.com/in/shawn-maisonneuve-10bb9415a/"
            target="_blank"
            rel="noreferrer">
                <FaLinkedin size={35} />
            </a>

            <a 
            className="hover:opacity-50 transition duration-500" 
            href="https://www.instagram.com/shvvvn__/"
            target="_blank"
            rel="noreferrer">
                <AiOutlineInstagram size={37} />
            </a>

            <a 
            className="hover:opacity-50 transition duration-500" 
            href="https://www.facebook.com/shawn.maisonneuve/"
            target="_blank"
            rel="noreferrer">
                <FaFacebookSquare size={35} />
            </a>

            <a 
            className="hover:opacity-50 transition duration-500 line-height:20px" 
            href="https://github.com/TheShawnZen"
            target="_blank"
            rel="noreferrer">
                <AiFillGithub size={37} />
            </a>
        </div>
    )
}
export default SocialMediaIcons;