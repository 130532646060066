import AnchorLink from "react-anchor-link-smooth-scroll";
import useMediaQuery from "../hooks/useMediaQuery";
import { AnimatePresence, motion, useCycle } from "framer-motion";

const links = [
  { page: "Accueil", link: "home", id: 1 },
  { page: "Compétences", link: "skills", id: 2 },
  { page: "Projets", link: "projects", id: 3 },
  { page: "Contact", link: "contact", id: 4 },
];
const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};
const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

const Link = ({ page, link, selectedPage, setSelectedPage }) => {
  const lowerCasePage = link.toLowerCase();
  return (
    <AnchorLink
      className={`${selectedPage === lowerCasePage ? "text-[#FDCC49]" : ""}
            hover:text-[#FDCC49] transition duration-500
            `}
      href={`#${link}`}
      onClick={() => setSelectedPage(lowerCasePage)}
    >
      {page}
    </AnchorLink>
  );
};

const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
  const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");
  const navbarBackground = isTopOfPage ? "" : "bg-deep-blue";
  const [open, cycleOpen] = useCycle(false, true);

  return (
    <div>
      {/*Desktop Nav*/}
      {isAboveSmallScreens ? (
        <nav className={`${navbarBackground} z-40 w-full fixed top-0 py-6`}>
          <div className="flex items-center justify-between mx-auto w-5/6">
            <h4 className="letters font-Montserrat text-3xl font-bold select-none"><span>S</span><span>M</span></h4>
            <div className="flex justify-between gap-16 font-Montserrat text-sm font-semibold">
              <Link
                page="Accueil"
                link="home"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                page="Compétences"
                link="skills"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                page="Projets"
                link="projects"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />

              <Link
                page="Contact"
                link="contact"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </nav>
      ) : (
        <nav className={`z-40 w-full fixed top-0 left-0 py-6`}>
          <div className="flex items-center justify-end mx-auto w-5/6">
        <main className="justify-end">
          <AnimatePresence>
            {open && (
              <motion.aside
                initial={{ width: 0 }}
                animate={{
                  width: 300,
                }}
                exit={{
                  width: 0,
                  transition: { delay: 0.7, duration: 0.3 },
                }}
              >
                <motion.div
                  className="container"
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={sideVariants}
                >
                  {links.map(({ page, link, id }) => (
                    <motion.a
                      key={id}
                      href={`#${link}`}
                      whileHover={{ scale: 1.1 }}
                      variants={itemVariants}
                      onClick={cycleOpen}
                    >
                      {page}
                    </motion.a>
                  ))}
                </motion.div>
              </motion.aside>
            )}
          </AnimatePresence>
          <div className="btn-container">
            <button onClick={cycleOpen}>
              {open ? (
                <img alt="close-icon" src="../assets/close-icon.svg" />
              ) : (
                <img alt="menu-icon" src="../assets/menu-icon.svg" />
              )}
            </button>
          </div>
        </main>
        </div>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
