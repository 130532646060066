import LineGradient from "../components/LineGradient";
import { AiFillGithub, AiOutlineLink } from 'react-icons/ai';
import { motion } from "framer-motion";

const container = {
    hidden:{},
    visible: {
        transition: {staggerChildren:0.2}
    }
};

const projectVariant = {
    hidden: {opacity:0, scale:0.8},
    visible: {opacity:1, scale:1}
}


const Project = ({title, desc, url, webUrl}) =>{
    const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
    bg-grey z-30 flex flex-col justify-center items-center p-2 sm:p-16 text-deep-blue max-sm:text-sm `

    return(
        <motion.div variants={projectVariant} className="relative max-sm:h-[250px] flex justify-center items-center">
            <div className={overlayStyles}>
                <p className="text-2xl font-montserrat font-bold">{title}</p>
                <p className="mt-7 xs:text-sm md:text-md text-center font-montserrat">{desc}</p>
                <div className="flex gap-2">
                {webUrl && <a 
            className="hover:opacity-50 transition duration-500  max-sm:line-height:20px" 
            href={webUrl}
            target="_blank"
            rel="noreferrer">
                <AiOutlineLink size={50} />
            </a>}
                  <a 
            className="hover:opacity-50 transition duration-500  max-sm:line-height:20px" 
            href={url}
            target="_blank"
            rel="noreferrer">
                <AiFillGithub size={50} />
            </a></div>
                
            </div>
            <img src={`../assets/${title}.jpg`} alt={title}/>
            
        </motion.div>
    )
}

const Projects = () => {
  return (
    <section id="projects" className="pt-48 pb-48">
      <motion.div
        className="md:w-2/4 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
        <p className="font-montserrat font-semibold text-4xl">
        <span className="letters select-none">
              <span>M</span>
              <span>e</span>
              <span>s</span>
              {" "}
            </span> <span className="text-yellow"> 
          <span className="letters select-none">
              <span>P</span>
              <span>r</span>
              <span>o</span>
              <span>j</span>
              <span>e</span>
              <span>t</span>
              <span>s</span>
            </span> </span>
        </p>
        </div>
        <div className="flex justify-center mt-5">
        <LineGradient width="w-1/3" />
        </div>
        <p className="mt-10 mb-10 font-montserrat">
          Voici les programmes que j'ai développé.
        </p>
      </motion.div>
      {/*Projects*/}
      <motion.div
        className="md:grid md:grid-cols-2 xl:grid-cols-3 "
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
        variants={container}
      >
        <div className="flex justify-center text-center items-center p-10 bg-red 
        w-auto max-h-[400px] txt-2xl font-montserrat font-semibold"> 
        Ce que j'ai créé
        </div>
        <Project title="AfterWork" desc="Ce site est une version améliorée de 
        la version originale du site d'AFTERWORK qui se trouve à être 
        une ligue de basket ball de Montréal. Le site permet aux administrateurs 
        de gérer les statistiques de la ligue et les inscriptions." 
        url="https://github.com/TheShawnZen/LAW" webUrl={"https://awleagues.netlify.app/"}/>

        <Project title="MemoRosemont" desc="Cette application web aide les utilisateurs à se 
        souvenir de ce qui est important grâce à nos flashcards uniques. Le site permet aux 
        utilisateurs de créer des sujets et des questions auxquelles ils peuvent ensuite répondre dans un test."
        url="https://github.com/TheShawnZen/flashcard"/>

        <Project title="Fotobook" desc="Ce site permet aux gens de publier des images sur leur compte 
        qui seront visibles au public ou seulement aux amis. Il est aussi possible de laisser un ♥
        et des commentaires sur les images publiées." 
        url="https://github.com/TheShawnZen/fotobook"/>

        <Project title="Spotter" desc="Ce site donne accès aux utilisateurs à une multitude d'activités 
        organisées partout dans le monde auxquelles ils peuvent participer. Il est aussi possible 
        d'organiser nos propres activités." 
        url="https://github.com/TheShawnZen/spotter"/>
        <div className="flex justify-center text-center items-center p-10 bg-blue 
        w-auto max-h-[400px] txt-2xl font-montserrat font-semibold"> 
        Et beaucoup plus avenir...
        </div>
      </motion.div>
    </section>
  );
};

export default Projects;
