import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import SocialMediaIcons from "../components/SocialMediaIcons";

const Landing = ({ setSelectedPage }) => {
  const isAboveMediumScreens = useMediaQuery("(min-width:1060px)");

  return (
    <section
      id="home"
      className="md:flex md:justify-between md:items-center sm:h-full md:h-[100vh]  md:mx-28 gap-16 py-10 "
    >
      {/*Image Section*/}
      <div className="md:order-2 flex justify-center basis-3/5 z-10 mt-16 md:mt-32 md:mb-4">
        {isAboveMediumScreens ? (
          <div
            className="relative z-0 md:ml-20 before:absolute before:-top-10 before:-left-10 before:shadow-neon-purple   before:animate-pulse
                    before:rounded-t-[400px] before:rounded-b-[400px] before:w-full before:max-w-[300px} before:h-full before:border-4 before:z-[-1] before:border-cyan-400"
          >
            <img
              alt="profile"
              src="assets/profile.jpg"
              className="hover:filter hover:saturate-150 transition duration-500 z-10 w-full
                        max-w-[300px] md:max-w-[300px] max-h-full h-auto rounded-full xl:min-w-[22vw]"
            />
            
          </div>
        ) : (<div
          className="relative z-0 md:ml-20 before:absolute  before:shadow-neon-purple   before:animate-pulse
                  before:rounded-t-[400px] before:rounded-b-[400px] before:w-full before:max-w-[300px} before:h-full before:border-4 before:z-[-1] before:border-cyan-400"
        >
          <img
            alt="profile"
            src="assets/profile.jpg"
            className="hover:filter hover:saturate-150 transition duration-500 z-10 w-full
            max-w-[300px] md:max-w-[300px] max-h-full h-auto rounded-full"
          />
          </div>
        )}
      </div>
      {/*Main Section*/}
      <div className="z-30 basis-2/5 mt-16 md:mt-24 md:-32">
        {/*Headings*/}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="text-5xl font-montserrat font-bold z-10 text-center md:text-start xs:font-semibold select-none">
            <span className="letters">
              <span>S</span>
              <span>h</span>
              <span>a</span>
              <span>w</span>
              <span>n</span>
            </span>
              <span className="letters xs:relative xs:font-semibold z-20">
                <span>M</span>
                <span>a</span>
                <span>i</span>
                <span>s</span>
                <span>o</span>
                <span>n</span>
                <span>n</span>
                <span>e</span>
                <span>u</span>
                <span>v</span>
                <span>e</span>
              </span>
          </p>{isAboveMediumScreens ? (
          <p className="w-[100%]">Développeur React</p>):(<p className="w-[100%] text-center">Développeur React</p>)}
          <div className="content">
            <div className="card relative flex text-center justify-center items-center select-none ">
              <div className="front  bg-deep-blue">
                <p className="md:text-xl mt-10 mb-7 font-montserrat text-md text-center md:text-start border-8 border-deep-blue">
                  Bonjour! Je suis un développeur basé à Montréal qui a soif
                  d'apprentissage et qui aimerait partager ses compétences au
                  sein d'une entreprise dynamique.
                </p>
              </div>
              <div className="back text-[48px] md:text-[56px] mt-10 mb-7 font-montserrat text-md text-center md:text-start absolute font-bold text-deep-blue">
                <p className=" bg-white rounded-sm mx-3 p-2">Kaizen.{""}</p>
                <p
                  className="mx-3 p-2 redAnimation"
                  
                >
                  改善{" "}
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        {/* Call to actions */}
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="bg-red hover:text-deep-blue  py-3 px-7 font-semibold
                    hover:bg-yellow transition duration-500 font-montserrat"
            onClick={() => setSelectedPage("contact")}
            href="#contact"
          >
            Contactez-moi
          </AnchorLink>
          <AnchorLink
            className="rounded-r-sm bg-red py-0.5 pr-0.5"
            onClick={() => setSelectedPage("contact")}
            href="#contact"
          >
            <div className="bg-deep-blue hover:text-yellow transition duration-500 w-full h-full flex items-center justify-center font-montserrat px-10 font-semibold">
              Discutons.
            </div>
          </AnchorLink>
        </motion.div>
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
