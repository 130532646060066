import LineGradient from "../components/LineGradient";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { FiMail } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import "../email.css";

const Contact = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  return (
    <section id="contact" className="py-48">
      <motion.div
        className="flex justify-end w-full"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div>
          <p className="font-montserrat font-semibold text-4xl">
          <span className="letters select-none">
              <span>C</span>
              <span>o</span>
              <span>n</span>
              <span>t</span>
              <span>a</span>
              <span>c</span>
              <span>t</span>
              <span>e</span>
              <span>z</span>
              <span>-</span>
            </span>
            <span className="text-yellow">
            <span className="letters select-none">
              <span>M</span>
              <span>o</span>
              <span>i</span>
            </span>
            </span>
          </p>
          <div className="flex justify-end my-5">
            <LineGradient width="w-1/2" />
          </div>
        </div>
      </motion.div>
      {/*Form*/}
      <div className="md:flex md:justify-between gap-16 mt-5">
        <motion.div
          className="basis-1/2 flex justify-center relative"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex flex-col  items-center">
            <div className="email">
              <a href="mailto:maisonneuve.s@hotmail.com">
                <FiMail size={60} />
              </a>
            </div>
          
          <div className="absolute bottom-4">
            <motion.a
            whileHover={{
                padding:"50px",
                scale:1.5,
                borderRadius: "100%"
              }} 
            className="contact2 top-10 bg-yellow p-2 rounded-3xl text-deep-blue" 
              href="../assets/Shawn Maisonneuve CV.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>C.V</p> <FiDownload className="bounce-4" size={40} />
            </motion.a>
          </div>
          </div>
        </motion.div>
        
        <motion.div
          className="basis-1/2 mt-10 md:mt-0"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <form
            target="_blank"
            onSubmit={onSubmit}
            action="https://formsubmit.co/9bb2f6df672870202de2b0403150889d"
            method="POST"
          >
            <input
              className="w-full bg-blue font-semibold placeholder-opaque-black p-3"
              type="text"
              placeholder="NOM"
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.name && (
              <p className="text-red mt-1">
                {errors.name.type === "required" && "Ce champs est requis."}
                {errors.name.type === "maxLength" &&
                  "Maximum de 100 caractères."}
              </p>
            )}
            <input
              className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
              type="text"
              placeholder="COURRIEL"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && (
              <p className="text-red mt-1">
                {errors.email.type === "required" && "Ce champs est requis."}
                {errors.email.type === "pattern" && "Courriel invalide."}
              </p>
            )}

            <textarea
              className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5 font-montserrat"
              type="text"
              placeholder="MESSAGE"
              rows="4"
              cols="50"
              {...register("message", {
                required: true,
                maxLength: 2000,
              })}
            />
            {errors.message && (
              <p className="text-red mt-1">
                {errors.message.type === "required" && "Ce champs est requis."}
                {errors.message.type === "pattern" &&
                  "Maximum de 2000 caractères."}
              </p>
            )}<div className="w-full flex justify-center items-center">
            <button
              type="submit"
              className="p-5 bg-yellow font-bold text-deep-blue mt-5
                 hover:bg-red hover:text-white transition duration-500  font-montserrat"
            >
              
              M'envoyer un message
            </button>
            </div>
          </form>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
